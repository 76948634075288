<template>
    <li class="settings-item pb-2" @input="onInput($event)" @inputTimezone="inputTimezone($event)">    
        <div class="settingsTitle mb-2" :style="{
            fontSize: fontSize ? fontSize + 'px' : '14px',
            color: color ? color : 'inherit'
            }">
            <img width="16px" class="mr-2" v-if="required" :src="isValueCorrect ? require('@/assets/icons/success-status.svg') : require('@/assets/icons/danger-status.svg')"/>
            {{ title }}
            <SmallHint class="ml-2" v-if="titleHintText" :text="titleHintText" :size="16"></SmallHint>
        </div>
        <div class="whatsapp-settings-form__setting">
            <slot></slot>
        </div>
        
    </li>
</template>

<script>
    import { getCurrentInstance, ref } from 'vue';
import SmallHint from '../../SmallHint.vue'
    export default{
        props:{
            fontSize: Number | String,
            required: Boolean,
            title: String,
            color: String,
            titleHintText: String,
            isBotConnected: Boolean
        },
        components:{
            SmallHint
        },
        setup(props){
            
            const inputValue = ref(null)
            const isValueCorrect = ref(props.isBotConnected ? true : false)
            const root = getCurrentInstance().proxy

            const onInput = (e) =>{
                const phonenumberRegex = /^\+7\d{10}$/;
                inputValue.value = e.target.value
                if (e.target.placeholder[0] === '+')
                    isValueCorrect.value = phonenumberRegex.test(e.target.value)
                else 
                    isValueCorrect.value = e.target.value?.length > 1 
            }

            const inputTimezone = (e) =>{
                if(e)
                isValueCorrect.value = true
            }

            root.$on('input', onInput)
            root.$on('inputTimezone', inputTimezone)

            return{
                inputValue,
                isValueCorrect,
                onInput
            }
        }
    }
</script>

<style scoped lang="sass">
    @import '@/vars.sass'
    .settingsTitle
        display: flex
        flex-direction: row
        align-items: center
    .settings-item
        margin-bottom: 10px
        border-bottom: 1px solid $light-gray
</style>